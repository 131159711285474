<template>
  <div class="produce-map">
    <div class="container" id="container"></div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { MAP_PK } from "@/common/constants";
import style from "@/common/mapfiles/style.json";
import rc from "@/common/mapfiles/rc.json";
export default {
  name: "",
  props: {
    farms: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      map: null,
    };
  },
  watch: {
    farms(n) {
      this.addFarmSource()
    }
  },
  methods: {
    initMap() {
      let mapParams = {
        container: "container",
        center: [105.59442, 29.40488],
        accessToken: MAP_PK,
        style,
        zoom: 9.5,
      };
      this.map = new mapboxgl.Map(mapParams);
      this.map.on("load", () => {
        this.addRCMap();
      });
    },
    addRCMap() {
      !this.map.getSource("rc_source") &&
        this.map.addSource("rc_source", {
          type: "geojson",
          data: rc,
        });
      !this.map.getLayer("rc_line_layer") &&
        this.map.addLayer({
          id: "rc_line_layer",
          source: "rc_source",
          type: "line",
          paint: {
            "line-width": 3,
            "line-color": "#08dddd",
            "line-opacity": 1,
          },
        });
      !this.map.getLayer("rc_fill_layer") &&
        this.map.addLayer({
          id: "rc_fill_layer",
          source: "rc_source",
          type: "fill",
          paint: {
            "fill-color": "rgba(8, 221, 221, 0.1)",
            "fill-opacity": 1,
            "fill-outline-color": "rgba(8, 221, 221, 0.1)",
          },
        });
    },
    addFarmSource() {
      if (!this.farms || this.farms.length == 0) return
    }
  },
  mounted() {
    this.initMap();
  },
};
</script>

<style lang="less" scoped>
.produce-map {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
  }
}
</style>